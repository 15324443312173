@import 'notability-services-common-frontend/scss/_common.scss';

.feature-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24;
  margin: 4px auto 60px;

  @media (max-width: $res-tablet-max) {
    flex-direction: column;
    align-items: center;
  }
}

.feature-card {
    position: relative;
    width: 248px;
    max-width: 100%;
    margin: 0 16px;
    padding: 0 16px;
    border-radius: 20px;
    text-align: center;

    @media (max-width: $res-tablet-max) {
      margin: 16px 0px 0px 0px;
    }

    .spanning-background {
      @media (max-width: $res-tablet-max) {
        position: absolute;
        top: 0;
        left: -1600px;
        right: -1600px;
        bottom: 0;
        z-index: -100;
      }
    }

    .image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      max-width: 180px;
      height: 152px;
      margin-left: auto;
      margin-right: auto;
    }

    .header {
      font-family: var(--font-proxima);
      font-weight: 700;
      font-size: 16px;
      margin-bottom: -4.8px;
      line-height: 21px;
      padding: 0px 24px;
      @media (max-width: $res-tablet-max) {
        font-size: 22px;
      }
    }
  }

.detached-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 32px 0px 60px;

  @media (max-width: $res-tablet-max) {
    flex-direction: column;
    align-items: center;
  }

  .feature-card-detached {
    position: relative;
    flex: 1;

    @media (max-width: $res-tablet-max) {
      width: 488px;
      max-width: 100%;
      margin: 16px 0px 0px 0px;
    }

    .spanning-background {
      @media (max-width: $res-tablet-max) {
        position: absolute;
        top: 0;
        left: -1600px;
        right: -1600px;
        bottom: 0;
        z-index: -100;
      }
    }

    .image {
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 1px 6px 0px rgba(23, 26, 32, 0.14);
    }

    img {
      border-radius: 8px;
    }

    .link-button {
      display: flex;
      position: absolute;
      bottom: 0;
      width: 34px;
      height: 35px;
      padding: 5px 5px 6px 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: #FFF;
      border-radius: 0px 8px 0px 8px;
      padding: 5px 5px 6px 5px;
      box-shadow: 0px 1px 6px 0px rgba(23, 26, 32, 0.14);
    }

    .header {
      font-family: var(--font-proxima);
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      @media (max-width: $res-tablet-max) {
        font-size: 22px;
      }
    }

    .description {
      align-self: flex-start;
      font-size: 17px;
      color: $core-f1;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
